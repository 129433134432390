import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import Portfolio from '../../components/Portfolio'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} title="Moje malování">
        <h1>Moje malování</h1>

        <Portfolio
          basepath="/portfolio"
          posts={this.props.data.portfolio.edges.map(({ node }) => node)}
        />
      </Layout>
    )
  }
}

export default HomeIndex

export const pageQuery = graphql`
  query PortfolioPageQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    portfolio: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//portfolio//" }
        frontmatter: { published: { eq: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          ...PortfolioPost
        }
      }
    }
  }
`
